<template>
  <div class="create">
    <!-- 箱子包含饰品 -->
    <div class="gunlist" v-show="isshow">
      <div class="close" @click="close"></div>
      <div class="box d-flex j-a">
        <div class="boxs d-flex j-a">
          <div
            class="gunarr"
            v-for="(item, index) in gunlist"
            :key="index"
            :class="`weapon-${item.color}`"
          >
            <img class="gun" :src="item.img" alt />
            <div class="name color-white">{{ item.name }}</div>
            <div class="money d-flex j-a">
              <div class="d-flex">
                <div>
                  <img class src="@/assets/roll/w-money.png" alt />
                </div>
                <div class="color-g" style="margin-right;5px;">
                  {{ item.payPrice }}
                </div>
              </div>
              <div class="color-white">{{ item.showProbability }}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 背景遮罩 -->
    <div class="bg" v-show="isshow"></div>
    <!-- 头部标题 -->
    <div class="header d-flex" @click="$router.push({ name: 'allpk' })">
      <img src="@/assets/images/pk/headimg.png" alt class="hl" />
      <div class="close" @click="$router.push({ name: 'allpk' })"></div>
      <div class="hr">
        <img src="@/assets/images/pk/fanhui.png" alt />
        <div>返回大厅</div>
      </div>
    </div>
    <!-- 房间模式 -->
    <div class="pattern">
      <div @click="OuHuang" :class="status == '1' ? 'active' : ''">
        欧皇模式
      </div>
      <div @click="Original" :class="status == '2' ? 'active' : ''">
        非酋模式
      </div>
      <!-- <div @click="team" :class="status == '3' ? 'active' : ''">组队模式</div> -->
    </div>
    <!-- 手机端房间人数 -->
    <div class="phonepeople">
      <div @click="two" :class="pk == '1' ? 'active1' : ''">双人房</div>
      <!-- <div @click="three" :class="pk == '2' ? 'active1' : ''">三人房</div> -->
    </div>
    <!-- 手机端箱子类型 -->
    <div class="d-flex">
      <div class="phonebox">
        <div
          class="pbi"
          v-for="(item, index) in box"
          :key="index + 'a'"
          @click="select(index, item.id)"
          :class="type == index ? 'az' : ''"
        >
          {{ item.name }}
        </div>
      </div>
      <span
        style="
          color: #00d2ff;
          margin-top: 8px;
          margin-right: 5px;
          font-size: 20px;
          font-weight: bold;
        "
      >
        >
      </span>
    </div>
    <!-- 手机端箱子 -->
    <div class="bs">
      <div
        class="bsx"
        v-for="(item, index) in shiPin"
        :key="index + 'q'"
        @click="selectBox(index)"
      >
        <img :src="item.img" alt class="bsxi" />
        <div class="on">{{ item.name }}</div>
        <div class="onm">
          <img src="@/assets/images/pk/jinbi.png" alt />
          <div>{{ item.price }}</div>
        </div>
      </div>
    </div>
    <!-- 盲盒选择 -->

    <div class="select">
      <div class="color-white selectTitle">
        物品选择
        <div class="small"></div>
      </div>
      <div
        class="d-flex j-a zt color-white"
        style="margin: 15px 0; font-size: 26px"
      >
        <div
          class="pbi"
          v-for="(item, index) in box"
          :key="index + 'a'"
          @click="select(index, item.id)"
          :class="type == index ? 'az' : ''"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="selectbox d-flex">
        <div
          v-for="(item, index) in shiPin"
          :key="index"
          :class="active == true ? 'select-item' : 'select-itemActive'"
        >
          <img
            :src="item.img"
            alt
            class="item-middle"
            @click="selectBox(index)"
          />
          <div class="item-header d-flex">
            <img src="@/assets/roll/w-money.png" alt />
            <div>{{ item.price }}</div>
          </div>
          <div class="item-footer">{{ item.name }}</div>
          <p class="f-13 t-c look color-g" @click="look(item.id)">查看饰品</p>
        </div>
      </div>
    </div>
    <div class="price">
      <!-- 选择箱子数 -->
      <div class="num d-flex justify color-white">
        <div class="right">已选择({{ boxLength }}/6)</div>
      </div>

      <div class="selectedWeapon">
        <div
          @click="deletebox(index)"
          class="item"
          v-for="(item, index) in selectedBox"
          :key="index"
        >
          <img :src="item.img" alt class="header" />
        </div>
      </div>
      <div class="total">{{ totalPrice }}</div>
    </div>
    <!-- 手机端部分样式 -->
    <div class="phone-n">
      <div class="nh">
        <div class="nhl">
          <div>已选择:</div>
          <div style="color: rgba(255, 240, 0, 1); margin-left: 4px">
            {{ boxLength }}个
          </div>
        </div>
        <div class="nhr">
          <div style="color: #fff; width: 40px">合计:</div>
          <img src="@/assets/images/pk/jinbi.png" alt />
          <div style="color: rgba(255, 240, 0, 1)">{{ totalPrice }}</div>
        </div>
      </div>
      <div class="nm">
        <div
          @click="deletebox(index)"
          v-for="(item, index) in selectedBox"
          :key="index + 'zx'"
          class="nmi"
        >
          <!-- <img src="@/assets/images/pk/x.png" alt class="cx" @click="deletebox(index)" /> -->
          <img :src="item.img" alt class="nming" />
          <div class="itm">{{ item.name }}</div>
          <div class="itb">
            <img src="@/assets/images/pk/jinbi.png" alt />
            <div>{{ item.price }}</div>
          </div>
        </div>
      </div>
    </div>
    <!--对战人数 -->
    <p class="color-white t-c f-20 renshu" style="margin-top: 10px">对战人数</p>
    <!-- 人数 -->
    <div class="patternPeople">
      <div @click="two" :class="pk == '1' ? 'active1' : ''">2</div>
      <!-- <div @click="three" :class="pk == '2' ? 'active1' : ''">3</div> -->
      <!-- <div @click="four" :class="pk == '3' ? 'active1' : ''">4</div> -->
    </div>
    <!-- 创建对战 -->

    <div class="createpk" @click="createPk">创建对战</div>
  </div>
</template>

<script>
import {
  createPkAPI,
  pkBoxes,
  getUserInfo,
  boxesType,
  boxOrnament,
} from "@/api";
import { mapState } from "vuex";

export default {
  inject: ["reload"],
  data() {
    return {
      active: true,
      status: "1",
      pk: "1",
      boxType: [],
      type: 0,
      mysteryBox: [],
      default: 0,
      totalPrice: 0,
      price: 0,
      selectedBox: [],
      boxLength: 0,
      ablePrice: 0,
      boxID: [],
      joinType: 1,
      teamModel: 3,
      userGold: 0,
      shiPin: [],
      box: [],
      gunlist: [],
      isshow: false,
    };
  },
  computed: {
    ...mapState(["userId", "userName"]),
  },
  async mounted() {
    let bean = (await getUserInfo()).data.user.bean;
    this.userGold = bean;
    let data = (await boxesType()).data.data;
    this.box = data;
    let cid = data[0].id;
    pkBoxes(cid).then((res) => {
      this.mysteryBox = res.data.rows;
      this.shiPin = res.data.rows;
    });
  },
  methods: {
    close() {
      this.isshow = false;
    },
    look(id) {
      boxOrnament(id).then((res) => {
        this.gunlist = res.data.rows;
        function compare(property) {
          return function (obj1, obj2) {
            var value1 = obj1[property];
            var value2 = obj2[property];
            return value2 - value1; // 降序
          };
        }
        this.gunlist.sort(compare("openPrice"));
      });
      this.isshow = true;
    },
    OuHuang() {
      this.status = "1";
      this.teamModel = 3;
    },
    Original() {
      this.status = "2";
      this.teamModel = 4;
    },
    team() {
      // console.log(111);
    },
    two() {
      this.pk = "1";
      this.joinType = 1;
      if (this.status == "3") {
        this.pk = "3";
      }
    },
    three() {
      this.pk = "2";
      this.joinType = 2;
      if (this.status == "3") {
        this.pk = "3";
      }
    },
    four() {
      this.pk = "3";
      this.joinType = 3;
    },
    select(index, id) {
      pkBoxes(id).then((res) => {
        this.shiPin = res.data.rows;
      });
      this.type = index;
    },
    // 选择武器
    selectBox(index) {
      //选择武器之前判断钱够不够
      this.ablePrice = this.ablePrice + this.shiPin[index].price;
      let gold = Number(this.userGold);
      let jg = Number(this.ablePrice.toFixed(2));
      if (gold >= jg) {
        //钱够用但是武器不能超过六个
        if (this.selectedBox.length < 6) {
          this.selectedBox.push(this.shiPin[index]);
          //获取箱子ID
          if (this.selectedBox.length == 1) {
            this.boxID.push(this.selectedBox[0].id);
          } else if (this.selectedBox.length == 2) {
            this.boxID.push(this.selectedBox[1].id);
          } else if (this.selectedBox.length == 3) {
            this.boxID.push(this.selectedBox[2].id);
          } else if (this.selectedBox.length == 4) {
            this.boxID.push(this.selectedBox[3].id);
          } else if (this.selectedBox.length == 5) {
            this.boxID.push(this.selectedBox[4].id);
          } else if (this.selectedBox.length == 6) {
            this.boxID.push(this.selectedBox[5].id);
          }
          this.price += this.shiPin[index].price;
          this.totalPrice = this.price.toFixed(2);
          this.boxLength = this.selectedBox.length;
        } else {
          this.$message({
            message: "最多只能添加六个箱子",
            type: "warning",
          });
        }
      } else {
        this.$message({
          message: "余额不足,请充值",
          type: "warning",
        });
      }
    },
    //删除武器
    deletebox(index) {
      this.price = this.totalPrice - this.selectedBox[index].price;
      this.totalPrice = this.price.toFixed(2);
      this.selectedBox.splice(index, 1);
      this.boxID.splice(index, 1);
      this.boxLength = this.selectedBox.length;
    },
    //创建对战
    createPk() {
      if (this.selectedBox.length == 0) {
        this.$message({
          message: "请先选择箱子",
          type: "warning",
        });
      } else {
        let data = {
          boxids: this.boxID,
          joinType: this.joinType,
          payCoin: this.totalPrice,
          type: this.teamModel,
          uId: this.userId,
          uname: this.userName,
        };
        // console.log(data);
        createPkAPI(data).then((res) => {
          if (res.data.code == 200) {
            this.reload();
            this.$router.push({ name: "Pk" });
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
// 背景隐藏层
.bg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 90;
  background-color: rgba(0, 0, 3, 0.774);
}
.create {
  width: 1375px;
  height: 719px;
  background: url("~@/assets/images/pk/createbg.png") no-repeat;
  background-size: 100% 100%;
  margin: 50px auto;
  overflow: hidden;
  .gunlist {
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 80%;
    background: url("~@/assets/images/pk/createbg.png") no-repeat;
    background-size: 100% 100%;
    overflow: auto;
    z-index: 95;
    .close {
      position: absolute;
      top: 35px;
      right: 60px;
      width: 40px;
      height: 40px;
      background: url("~@/assets/images/pk/close.png") no-repeat;
      background-size: 100% 100%;
    }
    .box {
      width: 90%;
      height: 580px;
      overflow: hidden;
      margin: 10px auto;
      padding: 80px 0;
    }
    .boxs {
      width: 90%;
      margin: 10px auto;
      flex-wrap: wrap;
      overflow: auto;
    }
    .gunarr {
      width: 212px;
      height: 242px;
      // background: url("~@/assets/images/pk/bg111.png") no-repeat;
      // background-size: 100% 100%;
      padding: 20px 0;
      box-sizing: border-box;
      margin: 5px 0;
      .gun {
        display: block;
        height: 120px;
        margin: auto;
      }
      .name {
        text-align: center;
        margin: 10px auto;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        // 没置超出部分显示省略号
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .money {
        width: 85%;
        margin: 10px auto;
        img {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
  .header {
    margin-top: 30px;
    justify-content: center;
    position: relative;
    .close {
      position: absolute;
      top: 0px;
      right: 130px;
      width: 40px;
      height: 40px;
      background: url("~@/assets/images/pk/close.png") no-repeat;
      background-size: 100% 100%;
      @media (max-width: 550px) {
        display: none;
      }
    }

    @media (max-width: 550px) {
      width: 100%;
      height: 40px;
      margin-top: 4px;
      overflow: hidden;
    }

    .hl {
      width: 910px;
      height: 41px;
      display: block;

      @media (max-width: 550px) {
        display: none;
        width: 480px;
        height: 30px;
        margin-left: 8px;
      }
    }

    .hr {
      display: none;

      @media (max-width: 550px) {
        display: flex;
        align-items: center;
        width: 110px;
        height: 40px;
        margin-left: -190px;
        //background-color: #fff;
      }

      img {
        @media (max-width: 550px) {
          width: 22px;
          height: 22px;
        }
      }

      div {
        @media (max-width: 550px) {
          width: 80px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          color: rgba(0, 210, 255, 1);
        }
      }
    }
  }

  .pattern {
    width: 452px;
    height: 45px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 10px auto;
    background: url("~@/assets/images/pk/modebg.png") no-repeat;
    background-size: 100% 100%;

    @media (max-width: 550px) {
      width: 350px;
      margin: auto;
      background: none;
    }

    div {
      width: 140px;
      height: 38px;
      line-height: 38px;
      color: white;
      text-align: center;
    }

    .active {
      width: 140px;
      height: 38px;
      line-height: 38px;
      color: white;
      text-align: center;
      background: url("~@/assets/images/pk/pactive.png") no-repeat;
      background-size: 100% 100%;

      @media (max-width: 550px) {
        font-size: 16px;
        color: rgba(255, 240, 0, 1);
        border-bottom: 2px solid rgba(255, 240, 0, 1);
      }
    }
  }

  .phonepeople {
    display: none;

    @media (max-width: 550px) {
      display: flex;
      justify-content: space-evenly;
      width: 90%;
      height: 40px;
      margin: 4px auto;
    }

    div {
      @media (max-width: 550px) {
        width: 80px;
        height: 38px;
        text-align: center;
        line-height: 38px;
        color: #fff;
        font-size: 15px;
        border: 1px dashed #fff000;
      }
    }

    .active1 {
      @media (max-width: 550px) {
        background-color: #fff000;
        color: black;
      }
    }
  }

  .phonebox {
    display: none;

    @media (max-width: 550px) {
      display: flex;
      width: 80%;
      height: 30px;
      margin: 6px auto;
      overflow: auto;
    }

    .pbi {
      @media (max-width: 550px) {
        flex: none;
        width: 90px;
        text-align: center;
        margin-right: 10px;
        line-height: 30px;
        color: #fff;
      }
    }

    .az {
      @media (max-width: 550px) {
        color: #fff000;
      }
    }
  }

  .bs {
    display: none;

    @media (max-width: 550px) {
      display: flex;
      width: 96%;
      height: 210px;
      margin: auto;
      overflow: auto;
      flex-wrap: wrap;
    }

    .bsx {
      @media (max-width: 550px) {
        width: 30%;
        height: 110px;
        margin-left: 9px;
        margin-bottom: 15px;
        flex: none;
        background-image: url("../../../assets/images/pk/bg444.png");
        background-size: 100% 100%;
      }

      .bsxi {
        @media (max-width: 550px) {
          display: block;
          width: 60%;
          height: 60%;
          margin: auto;
        }
      }

      .on {
        @media (max-width: 550px) {
          width: 80%;
          height: 20px;
          font-size: 12px;
          text-align: center;
          color: #fff;
          margin: auto;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .onm {
        @media (max-width: 550px) {
          width: 80%;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: auto;
        }

        img {
          @media (max-width: 550px) {
            width: 16px;
            height: 16px;
          }
        }

        div {
          @media (max-width: 550px) {
            margin-left: 6px;
            color: #fff000;
            font-size: 12px;
          }
        }
      }
    }
  }

  .select {
    .az {
      color: #fff000;
    }
    overflow: auto;
    margin: 10px auto;
    width: 76%;
    @media (max-width: 550px) {
      display: none;
    }

    .selectTitle {
      font-size: 18px;
      text-align: center;

      .small {
        width: 14px;
        margin: 15px auto 5px;
        border-top: 2px solid yellow;
      }
    }

    .selectbox {
      height: 180px;

      @media (max-width: 550px) {
        width: 350px;
        height: 280px;
        flex-wrap: wrap;
        overflow: auto;
      }

      .select-itemActive {
        position: relative;
        flex: none;
        width: 160px;
        height: 87px;
        background-image: url("~@/assets/roll/active.png");
        background-size: 100% 100%;
        margin-left: 10px;
      }

      .select-item {
        position: relative;
        flex: none;
        width: 160px;
        height: 87px;
        background-image: url("~@/assets/roll/noactive.png");
        background-size: 100% 100%;
        margin-left: 10px;

        @media (max-width: 550px) {
          margin-bottom: 60px;
        }

        .item-header {
          margin: 20px auto 0 auto;
          width: 50%;
          color: #eebc51;

          img {
            width: 20px;
            height: 20px;
          }

          div {
            font-size: 16px;
            margin-left: 4px;
          }
        }

        .item-middle {
          display: block;
          width: 80px;
          height: 68px;
          margin: auto;
        }

        .item-footer {
          color: #fff;
          font-size: 18px;
          text-align: center;
        }
      }
    }
  }

  .selectedWeapon {
    width: 600px;
    display: flex;

    .item {
      flex: none;
      width: 92px;
      height: 92px;
      background: url("~@/assets/images/pk/selectbox.png") no-repeat;
      background-size: 100% 100%;
      text-align: center;

      .header {
        margin-top: 15px;
        padding: 0px;
        width: 60px;
        height: 60px;
      }

      .footer {
        width: 30px;
        height: 30px;
        text-align: center;
      }
    }
  }

  .price {
    width: 95%;
    height: 80px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @media (max-width: 550px) {
      display: none;
    }

    .type {
      display: flex;
      overflow: auto;

      div {
        flex: none;
        width: 80px;
        height: 32px;
        margin-left: 10px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        line-height: 32px;
        background: #6967b6;
        border-radius: 32px;
      }

      .selected {
        background-color: #673ab7;
      }
    }

    .total {
      color: #fff;
      font-size: 24px;
    }

    .selected {
      margin-left: 40px;
      color: #fff;
      font-size: 14px;

      @media (min-width: 450px) {
        font-size: 24px;
        margin-left: 20%;
      }
    }

    .amount {
      margin-right: -20px;
      font-size: 14px;

      @media (min-width: 450px) {
        font-size: 24px;
        margin-right: -17%;
      }
    }

    .right {
      font-size: 14px;

      margin-right: 10px;

      @media (min-width: 450px) {
        font-size: 24px;
      }
    }

    .pi {
      display: none;
    }
  }

  .patternPeople {
    width: 230px;
    margin: 15px auto;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;

    @media (max-width: 550px) {
      display: none;
      margin: 6px auto;
    }

    div {
      width: 54px;
      height: 35px;
      line-height: 1.5;
      margin: 5px 0;
      font-weight: none;
      text-align: center;
      color: #b4b1a8;
      // background-color: #6b9afff1;
      background: url("~@/assets/images/pk/cpk.png") no-repeat;
      background-size: 100% 100%;
      // border-radius: 15px;
    }

    .active1 {
      width: 63px;
      height: 43px;
      text-align: center;
      line-height: 1.5;
      font-weight: none;
      margin: 5px 0;
      color: #eebc51;
      font-size: 20px;
      background: url("~@/assets/images/pk/cpk.png") no-repeat;
      background-size: 100% 100%;
      // border-radius: 15px;
      // background-color: #6191faf1;
    }
  }

  .renshu {
    display: block;

    @media (max-width: 550px) {
      display: none;
    }
  }

  .pkpeople {
    display: none;

    @media (max-width: 550px) {
      display: block;
      width: 100px;
      height: 30px;
      margin: 4px auto 0px;
    }
  }

  .createpk {
    width: 202px;
    height: 61px;
    margin: auto;
    color: white;
    text-align: center;
    font-size: 20px;
    line-height: 61px;
    background: url("~@/assets/images/pk/sure.png") no-repeat;
    background-size: 100% 100%;

    @media (max-width: 550px) {
      margin: 20px auto;
      background-image: url("../../../assets/images/pk/cpk1.png");
    }
  }

  .phone-n {
    display: none;
  }
}

@media (max-width: 550px) {
  .create {
    width: 100%;
    height: auto;
    margin: auto;
    background: none;
    overflow: auto;

    .phone-n {
      display: block;
      width: 340px;
      height: 115px;
      margin: auto;
      background-image: url("../../../assets/images/pk/phm.png");
      background-size: 100% 100%;

      .nh {
        height: 30px;
        margin-top: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .nhl {
          display: flex;
          color: #fff;
        }

        .nhr {
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: 100%;
            margin-right: 10px;
          }
        }
      }

      .nm {
        width: 100%;
        height: 90px;
        display: flex;
        overflow: auto;

        .nmi {
          flex: none;
          width: 80px;
          height: 100px;
          margin-left: 15px;
          margin-top: 8px;
          background-image: url("../../../assets/images/pk/bg444.png");
          background-size: 100% 100%;
          position: relative;

          .cx {
            position: absolute;
            top: -8px;
            right: -8px;
            width: 20px;
            height: 20px;
          }

          .nming {
            display: block;
            width: 80%;
            height: 60%;
            margin: auto;
          }

          .itm {
            width: 80%;
            height: 20px;
            text-align: center;
            line-height: 20px;
            font-size: 12px;
            margin: auto;
            color: #fff;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .itb {
            width: 80%;
            height: 20px;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            color: #fff000;

            img {
              width: 12px;
              height: 12px;
            }

            div {
              margin-left: 4px;
            }
          }
        }
      }
    }
  }
}
</style>
